import React from "react"
import { Link, graphql } from "gatsby";
import SliderImage from "../components/SliderImage";
import Menu from "../components/menu";
import AwesomeSlider from 'react-awesome-slider';
import AwesomeSliderStyles from 'react-awesome-slider/src/styled/cube-animation';

import SEO from "../components/seo"

class ProjectTemplate extends React.Component {
  render() {
    const project = this.props.data.markdownRemark
    const { previous, next, slider } = this.props.pageContext

    return (
      <>
        <SEO
          title={project.frontmatter.title}
          description={project.frontmatter.description || project.excerpt}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: 0,
                marginBottom: 0,
                display: 'block'
              }}
            >
              {project.frontmatter.title}
            </h1>
            <h3>{project.frontmatter.description}</h3>
          </header>
          <AwesomeSlider cssModule={AwesomeSliderStyles} bullets={false} className="screenshot-slider">
            {slider.map((current, index) => {
              return (
                <div>
                  <SliderImage imgName={current} />
                </div>
              )
            })}
          </AwesomeSlider>
          {/* <img alt="screenshot of project" src={`/project/${project.frontmatter.slug}.jpg`} /> */}
          <section dangerouslySetInnerHTML={{ __html: project.html }} />
          <hr
            style={{
              marginBottom: "30px",
            }}
          />
        </article>

        <nav>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`/project/${previous.frontmatter.slug}`} rel="prev">
                  ← <span>{previous.frontmatter.title}</span>
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`/project/${next.frontmatter.slug}`} rel="next">
                  <span>{next.frontmatter.title}</span> →
                </Link>
              )}
            </li>
          </ul>
        </nav>
        <Menu active="portfolio" />
      </>
    )
  }
}

export default ProjectTemplate

export const pageQuery = graphql`
  query projectBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        slug
        slider
      }
    }
  }
  `;